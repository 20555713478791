import { useEffect } from "react";

import axios from "axios";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";

import { userState } from "../recoil/state";

export default function useUser() {
  const [user, setUser] = useRecoilState(userState);
  const router = useRouter();
  const [cookies, setCookie, removeCookie] = useCookies();

  const logout = () => {
    removeCookie("token");
    router.push("/login");
  };
  const setUserByToken = (refreshToken) => {
    const body = {
      grant_type: "refresh_token",
      refresh_token: refreshToken
    };
    axios
      .post("/account/signin", body)
      .then((res) => {
        setUser(res.data);
        setCookie("token", res.data.token);
      })
      .catch((err) => {
        if (
          err.response.status === 400 &&
          err.response.data.code === "001-001"
        ) {
          alert(err.response.data.message);
          logout();
        }
      });
  };
  const setUserByLogin = (id, password) => {
    const body = {
      grant_type: "password",
      phone: id,
      password
    };
    axios.post("/account/signin", body).then((res) => {
      setUser(res.data);
      setCookie("token", res.data.token);

      router.push("/");
    });
  };

  useEffect(() => {
    if (!cookies.token) {
      // setUserByLogin()
    } else {
      router.push("/");
    }
  }, []);

  return { user, setUserByToken, setUserByLogin, logout };
}
