// import { useSetRecoilState } from "recoil";
import { useState } from "react";

// import Layout from "../components/layouts/layout";
import useUser from "../hooks/useUser";

export default function Login() {
  const { setUserByLogin } = useUser();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  console.log("deploy test");
  return (
    <div>
      <input
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      />
      <br />
      <input
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <button type="button" onClick={() => setUserByLogin(username, password)}>
        login
      </button>
    </div>
  );
}
